.course-form {
    .label-100 {
        label {
            width: 100%;
            display: flex;
        }

        .label-text-right {
            label {
                justify-content: flex-end !important;
            }
        }
    }
    .btn-wrapper {
        .ant-form-item-control-input-content {
            display: flex;
        }
    }

    .ant-collapse-header {
        display: flex;
        align-items: center;
        
    }

}
@primary-color: #2717bd;@themeColor: #2717bd;@font-family: 'Poppins', sans-serif;
.testimonial-item {
    height: 100%;
    text-align: left !important;
    background: #fff;
    box-shadow: 0px 2px 12px rgba(44, 73, 100, 0.08);
    padding: 20px;
}

.testimonial-item h3 {
    font-size  : 20px;
    font-weight: bold;
    color      : #111;
}

.testimonial-item h4 {
    font-size  : 14px;
    font-weight: 300;
    color      : #999;
}

.testimonial-item p {
    font-style: italic;
}

.testimonial .carousel {
    display       : flex;
    flex-direction: column-reverse;
}

.testimonial .carousel .control-dots {
    position: relative;
}

.testimonial .carousel .control-dots .dot {
    width     : 12px;
    height    : 12px;
    background: #ddd;
    box-shadow: none;
}

.testimonial .carousel .control-dots .dot {
    background: #007bff;
    box-shadow: none;
}

@media (max-width: 767px) {
    #testimonials .testimonial-item .testimonial-img {
        float : none;
        margin: auto;
    }

    #testimonials .testimonial-item h3,
    #testimonials .testimonial-item h4,
    #testimonials .testimonial-item p {
        margin-left: 0;
    }
}
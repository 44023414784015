@import '~antd/dist/antd.less';
@import '~bootstrap/dist/css/bootstrap.css';
@import '~aos/dist/aos.css';

.text-primary {
  color: @primary-color !important;
}

.bg-primary {
  background: @primary-color !important;
}

.app-container {
  background-color: #F0F2F5;
}

.bg-primary {
  background-color: #2C79EC;
}

.pointer {
  cursor: pointer;
}

// @media (max-width: 1440px) {
//   .container {
//     max-width: 100% !important;
//     padding: 24px;
//   }
// }

// @media (min-width: 1441px) {
//   .container {
//     max-width: 1440px;
//     padding: 24px;
//     margin: 0 auto 0 auto;
//   }
// }
@primary-color: #2717bd;@themeColor: #2717bd;@font-family: 'Poppins', sans-serif;
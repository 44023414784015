.header {

    height: 80px;
    box-shadow: 0px 2px 15px rgba(25, 119, 204, 0.1);
    padding: 15px 0px;

    .logo {
        font-size: 30px;
        margin: 0;
        padding: 0;
        line-height: 1;
        font-weight: 700;
        letter-spacing: 0.5px;
        font-family: "Poppins", sans-serif;
        height: 50px;
    }

    .logo img {
        max-height: 60px;
        height: 50px;
    }

    @media only screen and (max-width: 767px) {
        .header-logo {
            text-align: left;
            margin-left: 12px;
        }
    }

    .menu-item {
        font-weight: 500;
        font-size: 14px;
        position: relative;
        cursor: pointer;
        height: 100%;
        border-bottom: 2px solid #fff;
        display: flex;
        align-items: center;
    }

    .active {
        color: #2717bd;
        border-color: #2717bd;
    }

    .menu-item:hover {
        color: #2717bd;
        border-color: #2717bd;
    }

    .mobile-nav-popup {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100vh;
        width: 75vw;
        background: #2817bdc5;
        border-right: 4px solid #ffffff4f;
        box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
            0px 6px 16px rgba(0, 0, 0, 0.08),
            0px 9px 28px 8px rgba(0, 0, 0, 0.05);
        z-index: 10;

        .menu-item {
            height: 60px;
        }

        .menu-item {
            color: #fff;
            border-color: #fff;
        }

        .menu {
            padding: 30px 10px;
        }

    }

    .mobile-nav-popup a {
        font-weight: 500;
        font-size: 16px;
        padding: 5px 0px;
        color: #fff;
    }

    .mobile-nav-popup span {
        font-weight: 500;
        font-size: 16px;
        padding: 10px 0px;
    }
}

#topbar {
    background: #2717bd;
    font-size: 14px;
    transition: all 0.5s;
    z-index: 996;
    color: #fff;
    padding: 5px 0;
}

#topbar h5,
#topbar p {
    color: #fff;
    text-align: center;
    margin-bottom: 0px;
}
@primary-color: #2717bd;@themeColor: #2717bd;@font-family: 'Poppins', sans-serif;
#home {
    #hero {
        width: 100%;
        height: 90vh;
        background: url("../../images/hero-bg.jpg") top center;
        background-size: cover;
        margin-bottom: -200px;
    }

    #hero .container {
        position: relative;
    }

    #hero h1 {
        margin: 0;
        font-size: 48px;
        font-weight: 700;
        line-height: 56px;
        text-transform: uppercase;
        color: #fff;
    }

    #hero h2 {
        color: #fff;
        margin: 10px 0 0 0;
        font-size: 24px;
    }

    #hero .btn-get-started {
        font-family: "Raleway", sans-serif;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 1px;
        display: inline-block;
        padding: 12px 35px;
        margin-top: 30px;
        border-radius: 50px;
        transition: 0.5s;
        color: #fff;
        background: #2717bd;
    }

    #hero .btn-get-started:hover {
        background: #4c38c9;
    }

    #hero .btn-get-started-2 {
        font-family: "Raleway", sans-serif;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 1px;
        display: inline-block;
        padding: 12px 35px;
        margin-top: 30px;
        border-radius: 50px;
        transition: 0.5s;
        color: #fff;
        border: 2px solid #fff;
    }

    #hero .btn-get-started-2:hover {
        border: 2px solid #2717bd;
        background: #4c38c9;
    }

    
    @media (max-width: 992px) {
        #hero {
            margin-bottom: 0;
            height: 100vh;
        }

        #hero .container {
            padding-bottom: 63px;
        }

        #hero h1 {
            font-size: 28px;
            line-height: 36px;
        }

        #hero h2 {
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 30px;
        }
    }

    @media (max-height: 600px) {
        #hero {
            height: 110vh;
        }
    }

    /*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
    section {
        padding: 70px 0;
        overflow: hidden;
    }

    .section-bg {
        background-color: #f1fafd;
    }

    .section-title {
        text-align: center;
        padding-bottom: 30px;
    }

    .section-title h2 {
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 20px;
        padding-bottom: 20px;
        position: relative;
        color: #2717bd;
    }

    .section-title h2::before {
        content: "";
        position: absolute;
        display: block;
        width: 120px;
        height: 1px;
        background: #ddd;
        bottom: 1px;
        left: calc(50% - 60px);
    }

    .section-title h2::after {
        content: "";
        position: absolute;
        display: block;
        width: 40px;
        height: 3px;
        background: #2717BD;
        bottom: 0;
        left: calc(50% - 20px);
    }

    .section-title p {
        margin-bottom: 0;
    }

    /*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
    .breadcrumbs {
        padding: 20px 0;
        background-color: #f1f7fd;
        min-height: 40px;
        margin-top: 120px;
    }

    @media (max-width: 992px) {
        .breadcrumbs {
            margin-top: 100px;
        }
    }

    .breadcrumbs h2 {
        font-size: 24px;
        font-weight: 300;
        margin: 0;
    }

    @media (max-width: 992px) {
        .breadcrumbs h2 {
            margin: 0 0 10px 0;
        }
    }

    .breadcrumbs ol {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding: 0;
        margin: 0;
        font-size: 14px;
    }

    .breadcrumbs ol li+li {
        padding-left: 10px;
    }

    .breadcrumbs ol li+li::before {
        display: inline-block;
        padding-right: 10px;
        color: #6c757d;
        content: "/";
    }

    @media (max-width: 768px) {
        .breadcrumbs .d-flex {
            display: block !important;
        }

        .breadcrumbs ol {
            display: block;
        }

        .breadcrumbs ol li {
            display: inline-block;
        }
    }

    /*--------------------------------------------------------------
  # Why Us
  --------------------------------------------------------------*/
    .why-us .content {
        padding: 30px;
        background: #4c38c9;
        border-radius: 4px;
        color: #fff;
    }

    .why-us .content h3 {
        font-weight: 700;
        font-size: 34px;
        margin-bottom: 30px;
        color: #fff;
    }

    .why-us .content p {
        margin-bottom: 30px;
    }

    .why-us .content .more-btn {
        display: inline-block;
        background: rgba(255, 255, 255, 0.2);
        padding: 6px 30px 8px 30px;
        color: #fff;
        border-radius: 50px;
        transition: all ease-in-out 0.4s;
    }

    .why-us .content .more-btn i {
        font-size: 14px;
    }

    .why-us .content .more-btn:hover {
        color: #2717BD;
        background: #fff;
    }

    .why-us .icon-boxes .icon-box {
        text-align: center;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
        padding: 40px 30px;
        width: 100%;
    }

    .why-us .icon-boxes .icon-box i {
        font-size: 40px;
        color: #2717BD;
        margin-bottom: 30px;
    }

    .why-us .icon-boxes .icon-box h4 {
        font-size: 20px;
        font-weight: 700;
        margin: 0 0 30px 0;
    }

    .why-us .icon-boxes .icon-box p {
        font-size: 15px;
        color: #848484;
    }

    /*--------------------------------------------------------------
  # About
  --------------------------------------------------------------*/
    .about .icon-boxes h4 {
        font-size: 18px;
        color: #4b7dab;
        margin-bottom: 15px;
    }

    .about .icon-boxes h3 {
        font-size: 28px;
        font-weight: 700;
        color: #2717bd;
        margin-bottom: 15px;
    }

    .about .icon-box {
        margin-top: 40px;
    }

    .about .icon-box .icon {
        float: left;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 64px;
        height: 64px;
        border: 2px solid #4c38c9;
        border-radius: 50px;
        transition: 0.5s;
    }

    .about .icon-box .icon i {
        color: #2717BD;
        font-size: 32px;
    }

    .about .icon-box .icon i svg path {
        stroke: #2717BD;
    }

    .about .icon-box:hover .icon {
        background: #2717BD;
        border-color: #2717BD;
    }

    .about .icon-box:hover .icon i {
        color: #fff;
    }

    .about .icon-box:hover .icon i svg path {
        stroke: #fff;
    }

    .about .icon-box .title {
        font-weight: 700;
        margin-bottom: 0px;
        font-size: 18px;
        margin-left: 20px;
    }

    .about .icon-box .title a {
        color: #343a40;
        transition: 0.3s;
    }

    .about .icon-box .title a:hover {
        color: #2717BD;
    }

    .about .icon-box .description {
        margin-left: 85px;
        line-height: 24px;
        font-size: 14px;
    }

    .about .video-box {
        // background: url("../img/about.jpg") center center no-repeat;
        background-size: cover;
        min-height: 500px;
    }

    .about .play-btn {
        width: 94px;
        height: 94px;
        background: radial-gradient(#2717BD 50%, rgba(25, 119, 204, 0.4) 52%);
        border-radius: 50%;
        display: block;
        position: absolute;
        left: calc(50% - 47px);
        top: calc(50% - 47px);
        overflow: hidden;
    }

    .about .play-btn::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-40%) translateY(-50%);
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 15px solid #fff;
        z-index: 100;
        transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    .about .play-btn::before {
        content: "";
        position: absolute;
        width: 120px;
        height: 120px;
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
        -webkit-animation: pulsate-btn 2s;
        animation: pulsate-btn 2s;
        -webkit-animation-direction: forwards;
        animation-direction: forwards;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-timing-function: steps;
        animation-timing-function: steps;
        opacity: 1;
        border-radius: 50%;
        border: 5px solid rgba(25, 119, 204, 0.7);
        top: -15%;
        left: -15%;
        background: rgba(198, 16, 0, 0);
    }

    .about .play-btn:hover::after {
        border-left: 15px solid #2717BD;
        transform: scale(20);
    }

    .about .play-btn:hover::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-40%) translateY(-50%);
        width: 0;
        height: 0;
        border: none;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 15px solid #fff;
        z-index: 200;
        -webkit-animation: none;
        animation: none;
        border-radius: 0;
    }

    @-webkit-keyframes pulsate-btn {
        0% {
            transform: scale(0.6, 0.6);
            opacity: 1;
        }

        100% {
            transform: scale(1, 1);
            opacity: 0;
        }
    }

    @keyframes pulsate-btn {
        0% {
            transform: scale(0.6, 0.6);
            opacity: 1;
        }

        100% {
            transform: scale(1, 1);
            opacity: 0;
        }
    }

    /*--------------------------------------------------------------
  # Counts
  --------------------------------------------------------------*/
    .counts {
        background: #f1f7fd;
        padding: 70px 0 60px;
    }

    .counts .count-box {
        padding: 30px 30px 25px 30px;
        width: 100%;
        position: relative;
        text-align: center;
        background: #fff;
    }

    .counts .count-box i {
        position: absolute;
        top: -25px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 20px;
        background: #2717BD;
        color: #fff;
        border-radius: 50px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
    }

    .counts .count-box span {
        font-size: 36px;
        display: block;
        font-weight: 600;
        color: #082744;
    }

    .counts .count-box p {
        padding: 0;
        margin: 0;
        font-family: "Raleway", sans-serif;
        font-size: 14px;
    }

    /*--------------------------------------------------------------
  # Services
  --------------------------------------------------------------*/
    .services .icon-box {
        text-align: center;
        border: 1px solid #d5e1ed;
        padding: 80px 20px;
        transition: all ease-in-out 0.3s;
    }

    .services .icon-box .icon {
        margin: 0 auto;
        width: 64px;
        height: 64px;
        background: #2717BD;
        border-radius: 5px;
        transition: all 0.3s ease-out 0s;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        transform-style: preserve-3d;
    }

    .services .icon-box .icon i {
        color: #fff;
        font-size: 28px;
    }

    .services .icon-box .icon::before {
        position: absolute;
        content: "";
        left: -8px;
        top: -8px;
        height: 100%;
        width: 100%;
        background: #badaf7;
        border-radius: 5px;
        transition: all 0.3s ease-out 0s;
        transform: translateZ(-1px);
    }

    .services .icon-box h4 {
        font-weight: 700;
        margin-bottom: 15px;
        font-size: 24px;
    }

    .services .icon-box h4 a {
        color: #2717bd;
    }

    .services .icon-box p {
        line-height: 24px;
        font-size: 14px;
        margin-bottom: 0;
    }

    .services .icon-box:hover {
        background: #2717BD;
        border-color: #2717BD;
    }

    .services .icon-box:hover .icon {
        background: #fff;
    }

    .services .icon-box:hover .icon i {
        color: #2717BD;
    }

    .services .icon-box:hover .icon::before {
        background: #3291e6;
    }

    .services .icon-box:hover h4 a,
    .services .icon-box:hover p {
        color: #fff;
    }

    /*--------------------------------------------------------------
  # Appointments
  --------------------------------------------------------------*/
    .appointment .php-email-form {
        width: 100%;
    }

    .appointment .php-email-form .form-group {
        padding-bottom: 8px;
    }

    .appointment .php-email-form .validate {
        display: none;
        color: red;
        margin: 0 0 15px 0;
        font-weight: 400;
        font-size: 13px;
    }

    .appointment .php-email-form .error-message {
        display: none;
        color: #fff;
        background: #ed3c0d;
        text-align: left;
        padding: 15px;
        font-weight: 600;
    }

    .appointment .php-email-form .error-message br+br {
        margin-top: 25px;
    }

    .appointment .php-email-form .sent-message {
        display: none;
        color: #fff;
        background: #18d26e;
        text-align: center;
        padding: 15px;
        font-weight: 600;
    }

    .appointment .php-email-form .loading {
        display: none;
        background: #fff;
        text-align: center;
        padding: 15px;
    }

    .appointment .php-email-form .loading:before {
        content: "";
        display: inline-block;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        margin: 0 10px -6px 0;
        border: 3px solid #18d26e;
        border-top-color: #eee;
        -webkit-animation: animate-loading 1s linear infinite;
        animation: animate-loading 1s linear infinite;
    }

    .appointment .php-email-form input,
    .appointment .php-email-form textarea,
    .appointment .php-email-form select {
        border-radius: 0;
        box-shadow: none;
        font-size: 14px;
        padding: 10px !important;
    }

    .appointment .php-email-form input:focus,
    .appointment .php-email-form textarea:focus,
    .appointment .php-email-form select:focus {
        border-color: #2717BD;
    }

    .appointment .php-email-form input,
    .appointment .php-email-form select {
        height: 44px;
    }

    .appointment .php-email-form textarea {
        padding: 10px 12px;
    }

    .appointment .php-email-form button[type="submit"] {
        background: #2717BD;
        border: 0;
        padding: 10px 35px;
        color: #fff;
        transition: 0.4s;
        border-radius: 50px;
    }

    .appointment .php-email-form button[type="submit"]:hover {
        background: #4c38c9;
    }

    /*--------------------------------------------------------------
  # Departments
  --------------------------------------------------------------*/
    .departments {
        overflow: hidden;
    }

    .departments .nav-tabs {
        border: 0;
    }

    .departments .nav-link {
        border: 0;
        padding: 12px 15px 12px 0;
        transition: 0.3s;
        color: #2717bd;
        border-radius: 0;
        border-right: 2px solid #ebf1f6;
        font-weight: 600;
        font-size: 15px;
    }

    .departments .nav-link:hover {
        color: #2717BD;
    }

    .departments .nav-link.active {
        color: #2717BD;
        border-color: #2717BD;
    }

    .departments .tab-pane.active {
        -webkit-animation: fadeIn 0.5s ease-out;
        animation: fadeIn 0.5s ease-out;
    }

    .departments .details h3 {
        font-size: 26px;
        font-weight: 600;
        margin-bottom: 20px;
        color: #2717bd;
    }

    .departments .details p {
        color: #777777;
    }

    .departments .details p:last-child {
        margin-bottom: 0;
    }

    @media (max-width: 992px) {
        .departments .nav-link {
            border: 0;
            padding: 15px;
        }

        .departments .nav-link.active {
            color: #fff;
            background: #2717BD;
        }
    }

    .partners .slick-track {
        display: flex;
        align-items: center;
    }

}
@primary-color: #2717bd;@themeColor: #2717bd;@font-family: 'Poppins', sans-serif;
.course-card {
    transition: all 0.3s ease;
}

.course-card:hover {
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.payment-modal .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.5)
}

.dashboard {
    section {
        padding: 40px 0;
        margin: 20px;
        overflow: hidden;
    }

    .section-bg {
        background-color: #FEF6FF;
    }

    .section-title {
        text-align: center;
        padding-bottom: 30px;
    }

    .section-title h2 {
        position: relative;
    }

    .section-title h2::before {
        content: "";
        position: absolute;
        display: block;
        width: 120px;
        height: 1px;
        background: #ddd;
        bottom: -15px;
        left: calc(50% - 60px);
    }

    .section-title h2::after {
        content: "";
        position: absolute;
        display: block;
        width: 40px;
        height: 3px;
        background: #2717bd;
        bottom: -16px;
        left: calc(50% - 20px);
    }
}
@primary-color: #2717bd;@themeColor: #2717bd;@font-family: 'Poppins', sans-serif;